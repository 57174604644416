<template>
  <div class="content">
    <PageHeader title="" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header" style="border-bottom: 1px solid #fff;">
              <div class="w-100">
                <h3 class="card-title m-0 to-left">Webhooks</h3>
                <div class="to-right opts_card" style="margin-left: 10px;">
                  <!-- Ordenar Por -->
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <span class="material-icons title"> swap_vert </span
                      ><span class="sr-only">Search</span>
                    </template>
                    <div class="card">
                      <div class="card-header" style="display: flex; align-items: center">
                        {{ $t('generic-str.sort-by') }}
                        <b-dropdown-form class="close-btn">
                          <b-dropdown-item-button @click="closeDropdown"
                            ><span class="material-icons close-icon"> close </span>
                          </b-dropdown-item-button>
                        </b-dropdown-form>
                      </div>
                      <div class="card-body">
                        <ul class="list-unstyled">
                          <li>
                            <div class="control-group opt-2">
                              <label class="control control-checkbox">
                                <input type="checkbox" />
                                <div class="control_indicator"></div>
                                <span>Aberto</span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div class="control-group opt-2">
                              <label class="control control-checkbox">
                                <input type="checkbox" />
                                <div class="control_indicator"></div>
                                <span>Fechado</span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div class="control-group opt-2">
                              <label class="control control-checkbox">
                                <input type="checkbox" />
                                <div class="control_indicator"></div>
                                <span>Arquivado</span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </b-dropdown>
                  <!--/Ordenar Por -->
                  <a
                    v-modal="{ target: 'webhook-modal' }" class="c-pointer"
                  >
                    <img src="assets/img/icons/icon_plus.png" style="width: 37px; height: 37px;"/>
                  </a>
                </div>
                <div class="input-container to-right">
                  <form class="row" style="margin-right: 0;">
                    <input type="text" placeholder="Buscar" class="form-control" v-model="form.name">
                    <img src="assets/img/icons/icon_search.png" class="c-pointer" @click="fetch(1)"/>
                  </form>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="table-responsive">
                <div
                  v-if="!webhooks.length && fetched"
                  class="text-center table-placeholder"
                >
                  <br />
                  <i class="fas fa-plug font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('webhook-component.none')}}</h5>
                </div>
                <table v-if="webhooks && webhooks.length" class="table">
                  <thead>
                    <tr>
                      <th class="th-monitor">TIPO</th>
                      <th class="th-monitor">URL</th>
                      <th class="th-monitor">STATUS</th>
                      <th class="th-monitor"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(webhook, i) of webhooks" :key="i">
                      <td class="td-monitor">
                        <span v-if="webhook.type == 'sms_dlr'"
                          >Callback - SMS DLR</span
                        >
                        <span v-if="webhook.type == 'email_dlr'"
                          >Callback - Email DLR</span
                        >
                        <span v-if="webhook.type == 'email_mo'"
                          >Callback - Email MO</span
                        >
                        <span v-if="webhook.type == 'optout'"
                          >Callback - Optout</span
                        >
                        <span v-if="webhook.type == 'blacklist'"
                          >Callback - Blacklist</span
                        >
                        <span v-else-if="webhook.type == 'sms_mo'"
                          >Callback - SMS MO</span
                        >
                        <span v-else-if="webhook.type == 'lookup'"
                          >Callback - Lookup</span
                        >
                        <span v-else-if="webhook.type == 'whatsapp_dlr'"
                          >Callback - WhatsApp DLR</span
                        >
                        <span v-else-if="webhook.type == 'whatsapp_mo'"
                          >Callback - WhatsApp MO</span
                        >
                      </td>
                      <td class="td-monitor">
                        {{ webhook.url }}
                      </td>
                      <td class="td-monitor">
                        <span v-tooltip.top="'Ativo'" v-if="webhook.active" class="material-symbols-outlined icon-success">task_alt</span>
                        <span v-tooltip.top="'Desativado'" v-if="!webhook.active" class="material-symbols-outlined icon-failed">unpublished</span>
                      </td>
                      <td class="td-monitor">
                        <dropdown>
                          <template v-slot:text>
                            <span class="material-symbols-outlined title">
                              more_vert
                            </span>
                          </template>
                          <template #items>
                            <a
                              class="dropdown-item"
                              v-modal="{
                                target: 'edit-webhook-modal',
                                data: webhook,
                              }"
                              >
                                <span class="material-symbols-outlined span-icon"> border_color </span> <span>{{$t('generic-str.edit')}}</span>
                                <div style="clear: both;"></div>
                            </a>
                            <a
                              class="dropdown-item"
                              @click="remove(webhook.id)"
                              >
                              <span class="material-symbols-outlined span-icon">delete_forever </span> <span>{{$t('generic-str.remove')}}</span>
                              <div style="clear: both;"></div>
                            </a>
                            <a
                              v-if="webhook.active"
                              class="dropdown-item"
                              @click="toogle(webhook.id)"
                              >
                              <span class="material-symbols-outlined span-icon"> unpublished </span> <span>Desativar</span>
                              <div style="clear: both;"></div>
                            </a>
                            <a
                              v-if="!webhook.active"
                              class="dropdown-item"
                              @click="toogle(webhook.id)"
                              >
                              <span class="material-symbols-outlined span-icon"> publish </span> <span>Ativar</span>
                              <div style="clear: both;"></div>
                            </a>
                          </template>
                        </dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <webhook-modal id="webhook-modal" @sent="fetch(form.page)"></webhook-modal>
    <update-webhook-modal
      id="edit-webhook-modal"
      @sent="fetch(form.page)"
    ></update-webhook-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import WebhookService from '@/services/webhook.service';
import PageHeader from '@/components/PageHeader.vue';
import WebhookModal from '@/components/webhooks/WebhookModal.vue';
import UpdateWebhookModal from '@/components/webhooks/UpdateWebhookModal.vue';
import Pagination from '@/components/Pagination.vue';
import Dropdown from '@/components/Dropdown.vue';

export default {
  name: 'webhooks',
  components: {
    PageHeader,
    WebhookModal,
    UpdateWebhookModal,
    Pagination,
    Dropdown,
  },
  data() {
    return {
      fetched: false,
      webhooks: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      isSending: false,
      selectedwebhooks: [],
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      WebhookService.getWebhooks(this.form).then(
        (response) => {
          this.fetched = true;
          this.webhooks = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    toogle(id) {
      this.fetched = false;
      WebhookService.toggleWebhook(id).then(
        () => {
          this.fetch(this.form.page);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('webhook-component.remove.title'),
        text: this.$t('webhook-component.remove.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.fetched = false;
          WebhookService.removeWebhooks(id).then(
            () => {
              this.fetch(this.form.page);
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('webhook-component.remove.deleted'),
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.block-el {
  min-height: 300px;
}
.btn-remove {
  color: #fff;
  margin-bottom: 5px;
}
.btn-new {
  margin-bottom: 5px;
}
.color-white {
  color: #fff;
}
.form-search {
  float: right;
  width: 100%;
}
.form-search button {
  border: solid 1px #ccc;
  border-left: 0px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  height: 35px;
}
.form-search button i {
  font-size: 16px;
}
.w-100 {
  width: 100% !important;
}
.m-top-10 {
  margin-top: 10px;
}
td a i {
  font-size: 1.19rem;
}
.card-title {
  font-size: 26px !important;
  color: var(--foreground);
}
.m-0 {
  margin: 0px !important;
}
.to-left {
  float: left !important;
}
.input-container {
  float: right;
  position: relative;
  width: 25%;
}
.input-container img {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  margin-top: 10px;
  margin-right: 8px;
  cursor: pointer;
}
.to-right {
  float: right !important;
}
.table td, .table th, .table thead th {
  border-bottom: 1px solid #fff;
}
.th-monitor {
  color: var(--gray-font-color);
  font-size: 14px !important;
  border: #fff 1px solid !important;
  padding-top: 30px;
  padding-bottom: 30px;
  border-color: var(--table-border) !important;
}
.td-monitor {
  border-color: var(--table-border) !important;
  color: var(--gray-font-color-2) !important;
  font-weight: 300;
  color: #626262;
}
.btn-outline-rebrand {
  background: #fff;
  border: solid 1px #752DE6;
  font-size: 14px !important;
  color: #752DE6;
  padding: 10px;
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
  width: 166px;
  height: 40px;
}
.btn-outline-rebrand:hover {
  background: #F7F3FF;
}
.left-text-p{
  color: #000000;
  font-size: 16px;
  float: left;
  margin-left: 10px;
}
.left-text-p a {
  color: #000000;
}
.left-icon-img {
  float: left;
}
.list-left-icons {
  margin-bottom: 40px;
}
.filter-search .btn {
  padding: 0;
}
.icon_btn {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--form-border);
  border-left-color: transparent;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  span {
    color: var(--gray-font-color);
  }
}
.c-pointer {
  cursor: pointer !important;
}
.dropdown-menu-edit {
  color: #9CA7B0;
}
.dropdown-menu-edit a span {
  font-size: 14px;
  float: left;
}
.span-icon {
  font-size: 20px !important;
  margin-right: 5px;
}
.icon-success {
  color: #6eda2c;
}

.icon-failed {
  color: #ff0000;
}
</style>
