import axios from '@/plugins/axios';

class WebhookService {
  getWebhooks(data) {
    this.data = data;
    return axios().get('webhooks', { params: this.data }).then((response) => response.data);
  }

  toggleWebhook(id) {
    this.id = id;
    return axios().put(`webhooks/${id}/toogle`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  updateWebhook(id, data) {
    this.id = id;
    return axios().put(`webhooks/${id}`, data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  removeWebhooks(id) {
    this.id = id;
    return axios().delete(`webhooks/${id}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  createWebhook(data) {
    this.data = data;
    return axios().post('webhooks', this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }
}

export default new WebhookService();
