<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('webhook-component.new-webhook')}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <div class="col-md-12">
                  <label class="control-label text-left">{{$t('generic-str.type')}}</label>
                  <select class="form-control" v-model="form.type" required>
                    <option value="sms_dlr">Callback - SMS DLR</option>
                    <option value="sms_mo">Callback - SMS MO</option>
                    <option value="sms_lookup">Callback - SMS LOOKUP</option>
                    <option value="whatsapp_dlr">Callback - WhatsApp DLR</option>
                    <option value="whatsapp_mo">Callback - WhatsApp MO</option>
                    <option value="email_mo">Callback - Email MO</option>
                    <option value="email_dlr">Callback - Email DLR</option>
                    <option value="blacklist">Callback - Deny list</option>
                    <option value="optout">Callback - Opt out</option>
                  </select>
                </div>
                <div class="col-md-12">
                  <label class="control-label text-left">URL</label>
                  <input
                    required
                    v-model="form.url"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
            >
              {{$t('generic-str.save')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WebhookService from '@/services/webhook.service';
import Modal from '@/mixins/Modal';

export default {
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      form: {
        type: 'sms_dlr',
        url: '',
      },
      isSending: false,
    };
  },
  methods: {
    save() {
      WebhookService.createWebhook(this.form).then(
        () => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Webhook Cadastrado',
            type: 'success',
          });
          this.$emit('sent');
          this.isSending = false;
          this.hide();
        },
        (error) => {
          console.log(error.response.data.message);
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.vti__dropdown-list {
  z-index: 1000 !important;
}
.vue-tel-input {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
}
.loading-cep {
  right: 40px;
  top: 40px;
  position: absolute;
  padding: initial;
}
</style>
